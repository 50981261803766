import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  smoothScroll('a[href^="#"]', { offset: 100 })

  $(document).scroll(function () {
    const scroll = $(this).scrollTop()
    if (scroll > 0) {
      $('.header').addClass('fixed')
      $('.anniversary-widget--wrapper').addClass('mobile')
    } else {
      $('.header').removeClass('fixed')
      $('.anniversary-widget--wrapper').removeClass('mobile')
    }
  })

  $('.team-member--wrapper').hover(function () {
    if (!$(this).hasClass('placeholder')) {
      $(this).addClass('current')
      $(this).siblings().addClass('notCurrent')
    }
  }, function () {
    if (!$(this).hasClass('placeholder')) {
      $('.team-member--wrapper').siblings().removeClass('notCurrent')
      $('.team-member--wrapper').removeClass('current')
    }
  })

  document.querySelectorAll('.team-member--wrapper').forEach(item => {
    if (!item.classList.contains('placeholder')) {
      item.addEventListener('click', event => {
        const parent = item.closest('.neos-contentcollection')
        const teamWrapper = document.querySelector('.team--wrapper')

        // Prüfen, ob das geklickte Element das .item_close ist
        if (event.target.classList.contains('item_close') || event.target.closest('.item_close')) {
          return
        }

        // Toggle Verhalten für das geklickte Element
        const isCurrentFull = item.classList.contains('current_full')

        // Setze alle Elemente zurück
        parent.querySelectorAll('.team-member--wrapper').forEach(el => {
          el.classList.remove('current_full', 'notCurrent_full')
          el.style.top = '' // Reset top position
        })

        if (!isCurrentFull) {
          // Berechne den Abstand nach oben für das aktuelle Element
          const offsetTop = item.offsetTop - teamWrapper.offsetTop

          // Setze die Klasse und den Abstand für das aktuelle Element
          item.classList.add('current_full')
          item.style.top = `${offsetTop}px`

          // Setze die Klassen für die anderen Elemente
          parent.querySelectorAll('.team-member--wrapper').forEach(el => {
            if (el !== item) {
              el.classList.add('notCurrent_full')
            }
          })
        }
      })

      // Event-Listener für das .item_close Element
      item.querySelector('.item_close').addEventListener('click', event => {
        event.stopPropagation() // Verhindert das Auslösen des Klick-Events des Eltern-Elements
        const parent = item.closest('.neos-contentcollection')

        // Entferne die Klassen current_full und notCurrent_full von allen Elementen
        parent.querySelectorAll('.team-member--wrapper').forEach(el => {
          el.classList.remove('current_full', 'notCurrent_full')
          el.style.top = '' // Reset top position
        })
      })
    }
  })

  // show animation at the leistungen page when scroll to the appropriate position
  $(window).scroll(function () {
    $('.lesitung_ani').each(function () {
      const aniPos = $(this).offset().top
      const topOfWindow = $(window).scrollTop()

      if (aniPos < topOfWindow + 450) {
        $(this).addClass('ani')
      }
    })

    if ($(this).scrollTop() >= 600) {
      $('.back-to-top').fadeIn(300).addClass('visible')
    } else {
      $('.back-to-top').fadeOut(300).removeClass('visible')
    }
  })
})

const categoryTitle = document.querySelectorAll('.filter-button')
const allCategoryPosts = document.querySelectorAll('.project-teaser.item')

for (let i = 0; i < categoryTitle.length; i++) {
  categoryTitle[i].addEventListener('click', filterPosts.bind(this, categoryTitle[i]))
}

function filterPosts (item) {
  for (let i = 0; i < allCategoryPosts.length; i++) {
    if (allCategoryPosts[i].classList.contains(item.attributes.id.value)) {
      allCategoryPosts[i].style.display = 'block'
    } else {
      allCategoryPosts[i].style.display = 'none'
    }
  }
};

document.body.querySelectorAll('.team--wrapper .team-member--wrapper').forEach(function (node, i) {
  node.classList.add(i % 2 ? 'right' : 'left')
})

$(document).ready(function () {
  // Add class 'active' to section when near top of viewport
  const allSections = document.querySelectorAll('.graph--wrapper')
  const serviceWrapper = document.querySelectorAll('.service-wrapper')
  const allContainer = document.querySelectorAll('.grid-content')

  window.onscroll = function () {
    allSections.forEach(section => {
      if (section.getBoundingClientRect().top <= 600) {
        section.classList.add('active')
      }
    })

    serviceWrapper.forEach(section => {
      if (section.getBoundingClientRect().top <= 800) {
        section.classList.add('active')
      }
    })

    allContainer.forEach(section => {
      if (section.getBoundingClientRect().top <= 800) {
        section.classList.add('active')
      }
    })
  }
})

$(window).on('load resize', function () {
  function distanceBetweenElements (elementOne, elementTwo) {
    let distance = -1

    if (!elementOne.offset() || !elementTwo.offset()) {
      return
    }

    const x1 = elementOne.offset().top
    const y1 = elementOne.offset().left
    const x2 = elementTwo.offset().top
    const y2 = elementTwo.offset().left
    const xDistance = x1 - x2
    const yDistance = y1 - y2

    distance = Math.sqrt(
      (xDistance * xDistance) + (yDistance * yDistance)
    )

    return distance + 'px'
  }

  const firstStep = $('.steps-elements--wrapper .steps-element:first-child() picture')
  const lastStep = $('.steps-elements--wrapper .steps-element:last-child() picture')

  const spaceBetweenApplicationTraining = distanceBetweenElements(firstStep, lastStep)

  firstStep.css('--widthLine', (spaceBetweenApplicationTraining))
})

const counters = document.querySelectorAll('.counter span')
const counterContainer = document.querySelector('.fact--wrapper')
let activated = false

function isInViewport (element) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= -100 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  )
}

window.addEventListener('scroll', () => {
  if (isInViewport(counterContainer) && !activated) {
    counters.forEach(counter => {
      counter.innerText = 0
      const target = parseInt(counter.dataset.count)
      const steps = 50
      const duration = 2000
      const stepValue = Math.ceil(target / steps)
      let currentCount = 0

      function updateCount () {
        currentCount += stepValue
        if (currentCount >= target) {
          counter.innerText = target
        } else {
          counter.innerText = currentCount
          setTimeout(updateCount, duration / steps)
        }
      }
      updateCount()
      activated = true
    })
  } else if (!isInViewport(counterContainer) && activated) {
    counters.forEach(counter => {
      counter.innerText = 0
    })
    activated = false
  }
})

document.addEventListener('DOMContentLoaded', function () {
  const panels = document.querySelectorAll('.customer-panel')
  panels.forEach(function (panel, index) {
    const id = 'readmore' + (index + 1)
    const desContainer = panel.querySelector('.des')
    const contentHeight = desContainer.scrollHeight
    panel.querySelector('input[name="readmore"]').setAttribute('id', id)
    panel.querySelector('label').setAttribute('for', id)
    // panel.querySelector('.des').style.maxHeight = contentHeight + 'px';
    // panel.querySelector('.des').setProperty('--max-height', contentHeight + 'px');
  })
})

document.addEventListener('DOMContentLoaded', function () {
  const panels = document.querySelectorAll('.customer-panel')

  function adjustContentHeight (panel) {
    const input = panel.querySelector('input[name="readmore"]')
    const desContainer = panel.querySelector('.des')

    if (input.checked) {
      const contentHeight = desContainer.scrollHeight
      desContainer.style.maxHeight = contentHeight + 'px'
    } else {
      desContainer.style.maxHeight = '180px'
    }
  }

  function adjustAllContentHeights () {
    panels.forEach(function (panel) {
      adjustContentHeight(panel)
    })
  }

  // Initial anpassen
  adjustAllContentHeights()

  // Anpassen, wenn das Fenster skaliert wird
  window.addEventListener('resize', function () {
    adjustAllContentHeights()
  })

  // Anpassen, wenn der Nutzer das Input-Element auswählt/abwählt
  panels.forEach(function (panel) {
    const input = panel.querySelector('input[name="readmore"]')
    input.addEventListener('change', function () {
      adjustContentHeight(panel)
    })
  })
})
